<template>
  <div class="form-group relative">
    <p v-if="showLabel" class="mb-0">{{ label }}<sup v-if="required" class="display-flex required">*</sup></p>
    <dropdown-wrap-vue @onClickOutside="onClickOutside">
      <b-input
        type="search"
        ref="input"
        @focus="onFocus"
        @input="updateSearchText"
        :icon-right="openDropdown ? 'menu-up' : 'menu-down'"
        :value="displayValue"
        class="form-control" />
      <common-selection
        id="id"
        name="name"
        :radioInputName="listName"
        @focus="onChangeSelection"
        :searchText="searchText"
        :open="openDropdown"
        :selected="selected"
        :inputType="inputType"
        :list="list" />
    </dropdown-wrap-vue>
  </div>
</template>

<script>
import CommonSelection from '../../../components/Dropdowns/CommonSelection.vue'
import DropdownWrapVue from '../../../components/Common/DropdownWrap.vue'
export default {
  name: 'SearchDropDown',
  components: {
    CommonSelection,
    DropdownWrapVue
  },
  props: {
    /** This prop indicates whether an input is required or not */
    required: Boolean,
    /** This prop contains the text for the label for the input */
    label: String,
    /** This prop indicates whether a form submission attempt has taken place */
    submitted: Boolean,
    /** This prop contains a list of options that is populated within the dropdown */
    list: Array,
    showLabel: Boolean,
    selected: Object,
    fieldIndex: Number
  },
  data () {
    return {
      localSelected: {
        id: '',
        name: ''
      },
      openDropdown: false,
      searchText: '',
      inputType: 'radio',
      listName: 'companyListItem',
      displayValue: ''
    }
  },
  watch: {
    selected (newValue, oldValue) {
      this.localSelected = newValue ? this.list?.find(obj => obj.name === newValue.name) : { id: '', name: '' }
      this.searchText = newValue ? newValue.name : null
      this.displayValue = newValue ? newValue.name : null
    }
  },
  mounted () {
    if (this.selected) {
      this.localSelected = this.list?.find(obj => obj.name === this.selected.name)
      this.searchText = this.selected.name
      this.displayValue = this.selected.name
    }
  },
  methods: {
    /** This method selects an option from the dropdown list
     * @param name {String} - The name of the product
     * @param isValid {Boolean}
     * @public
     */
    onChangeSelection (obj) {
      this.localSelected = obj
      this.displayValue = obj.name
      this.$emit('onChange', obj, this.fieldIndex)
    },
    /** This method closes the dropdown list menu if a click is detected outside its boundary, and sets the external value of the input field to the name of the selected option
     * @public
     */
    onClickOutside () {
      this.openDropdown = false
      this.displayValue = this.localSelected?.name ? this.localSelected?.name : ''
    },
    /** This method opens the dropdown list when it is focused on, and clears the search function internally (therefore displaying the full list)
     * @public
     */
    onFocus () {
      this.searchText = ''
      this.openDropdown = true
    },
    /**
     * This method updates the value of the input field internally, and allows for the search function to work
     * @param value {String} - The value passed to the search function
     * @public
     */
    updateSearchText (value) {
      this.searchText = value
      // DONT REMOVE
      // This line is required otherwise if the input is empty and the dropdown is closed, the input is not able to set the value of localSelected.name
      this.displayValue = value
    }
  }
}
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
p {
  sup {
      font-family: Roboto;
      font-size: 12px;
    }
}
</style>
