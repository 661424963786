<template>
  <div class="new-clients-container">
    <admin-header-vue
      alias=""
      pageName="Update News Categories" />
    <form @submit.prevent="handleSubmit">
      <div class="new-client form-group">
        <div class="columns">
          <div class="column is-3">
            <p>Module<sup class="display-flex required">*</sup></p>
            <search-drop-down
            label=""
            :showLabel="true"
            :value="newNewsCategory.moduleIds"
            :list="moduleOptions"
            @onChange="onChange"
            :selected="selectedModule" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :min="1"
              type="text"
              :max="250"
              :submitted="submitted"
              @onChangeName="onChangeName"
              :alphaNumAndSpecialChar="true"
              :required="true"
              :value="newNewsCategory.label"
              placeholder="Enter category name"
              label="Category" />
          </div>
          <div class="column is-4">
            <p>Parent Category</p>
          <treeselect
            :multiple="false"
            :options="parentNewsCategories"
            :sort-value-by="sortValueBy"
            :default-expand-level="1"
            placeholder="Select Parent Category"
            v-model="newNewsCategory.parentId"  />
          </div>
          <div class="column is-4">
            <p>Enable category?</p>
            <b-checkbox v-model="categoryStatus"> </b-checkbox>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <input-vue

              type="textarea"
              :submitted="submitted"
              @input="handleInclusionInput"
              :value="formInclusions"
              label="Inclusion Keywords "
              placeholder="Enter keywords, one per line" />
              <div
                class="required-error"
                v-if="submitted && isInclusionsInvalid">
                {{inclusionsError}}
              </div>

          </div>
          <div class="column is-4">
            <input-vue
              :required="false"
              type="textarea"
              :submitted="submitted"
              @input="handleExclusionInput"
              :value="formExclusions"
              label="Exclusion Keywords "
              placeholder="Enter keywords, one per line" />
              <div
                class="required-error"
                v-if="submitted && isExclusionsInvalid">
                {{exclusionsError}}
              </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Update</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import InputVue from '../../components/Input.vue'
import Snackbar from '../../../../components/Snackbar'
import SearchDropDown from '../../components/SearchDropDown.vue'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
/**
 * Form to Update a News Category.
 * @displayName UpdateNewsCategory
 */
export default {
  name: 'UpdateNewsCategory',
  components: {
    AdminHeaderVue,
    InputVue,
    SearchDropDown,
    Treeselect
  },
  data () {
    return {
      /**
       * Object containing form data which will be sent to the API
       * @type {Object}
       */
      openProduct: false,
      totalRows: 0,
      limit: 200,
      offset: 0,
      keywordsLimit: 5000,
      categoryStatus: '',
      formInclusions: '',
      formExclusions: '',
      moduleFilter: [],
      newNewsCategory: {
        label: null,
        status: 'inactive',
        parentId: null,
        inclusionKeywords: [],
        exclusionKeywords: [],
        moduleIds: []
      },
      /**
       * Field to track the form submission status, to be used to show/hide the validation messages
       * @type {Boolean}
       */
      submitted: false,

      /**
       * Field to track if the category name input is valid
       * @type {Boolean}
       */
      isCategoryNameValid: true,

      /**
       * Field to track if the category input is valid
       * @type {Boolean}
       */
      isCategoryIdValid: true,

      /**
       * Field to track if the tagging keyword inclusions input is valid
       * @type {Boolean}bo
       */
      isInclusionsInvalid: false,

      /**
       * Field to track if the tagging keyword exclusions input is valid
       * @data
       * @type {Boolean}
       */
      isExclusionsInvalid: false,
      isInclusionEmpty: true,
      inclusionsError: '',
      exclusionsError: '',
      selectedModule: {
        id: null,
        name: null
      },
      sortValueBy: 'ORDER_SELECTED'
    }
  },
  computed: {
    ...mapState('newsCategories', ['categories', 'totalCount', 'totalLength', 'newsCategoryDetails', 'parentNewsCategories']),
    ...mapState('filters', ['esgCategoriesListFlat']),
    ...mapState('modules', ['moduleList']),
    ...mapState('user', ['userDetails']),

    moduleOptions () {
      if (this.moduleList && this.moduleList.length) {
        return this.moduleList.filter(m => m.module_name === 'esg-controversies').map(({ module_id: id, module_name: name }) => ({ id, name }))
      } else {
        return []
      }
    }

  },
  watch: {
    esgCategoriesListFlat () {
      this.updateSelectedEsgCategories(this.esgCategoriesListFlat.map(elem => elem.id))
    },
    newsCategoryDetails () {
      const {
        label,
        status,
        parent,
        inclusionKeywords,
        exclusionKeywords,
        modules
      } = this.newsCategoryDetails
      if (label) { this.newNewsCategory.label = label }
      if (parent) { this.newNewsCategory.parentId = parent.id }
      if (status) {
        if (status === 'active') { this.categoryStatus = true } else {
          this.categoryStatus = false
        }
      }
      if (inclusionKeywords) {
        this.formInclusions = inclusionKeywords.join('\n')
        this.newNewsCategory.inclusionKeywords = inclusionKeywords
        this.isInclusionEmpty = false
      } else {
        this.isInclusionEmpty = true
      }
      if (exclusionKeywords) {
        this.formExclusions = exclusionKeywords.join('\n')
        this.newNewsCategory.exclusionKeywords = exclusionKeywords
      }
      if (modules) {
        this.moduleFilter = modules.filter(m => m.name === 'esg-controversies')

        if (this.moduleFilter && this.moduleFilter.length > 0) {
          this.selectedModule = this.moduleFilter[0]
          this.newNewsCategory.moduleIds[0] = this.moduleFilter[0].id
        } else {
          this.newNewsCategory.moduleIds = []
        }
      }
    }

  },

  mounted () {
    this.getModuleList()
    this.getDataById()
  },
  methods: {
    ...mapActions('newsCategories', ['getNewsCategoryList', 'getNewsCategoryById', 'updateNewsCategory']),
    ...mapActions('filters', ['updateSelectedEsgCategories', 'getNewsCategoryListGlobalFilter']),
    ...mapActions('modules', ['getModuleList']),

    getData () {
      if (this.newNewsCategory.moduleIds && this.newNewsCategory.moduleIds.length > 0) {
        this.getNewsCategoryList({
          limit: this.limit,
          offset: this.offset,
          moduleIds: this.newNewsCategory.moduleIds,
          status: 'all',
          orderBy: 'label'
        })
      }
    },
    async getDataById () {
      const res = await this.getNewsCategoryById({
        categoryId: this.$route.params.id
      })
      if (res === 200) {
        this.getData()
      }
    },

    /**
   * Gets called on input in "Category Name" field. It sets the input value as categoryName in the newNewsCategory object used for request data.
   * @param {String} data
   */
    onChangeName (name, isValid) {
      this.newNewsCategory.label = name
      this.isCategoryNameValid = isValid
    },

    onChange (value) {
      this.selectedModule = value
      this.newNewsCategory.moduleIds[0] = value.id
      this.getData()
    },

    /**
     * Handles the keyword input in inclusions field and prepares the array from the string input using the delimiter as "\n"(newline).
     * @param {String} data
     * @public
     */
    handleInclusionInput (data) {
      if (data) {
        this.isInclusionEmpty = false
        const keywords = data.split('\n')
        if (keywords.length > this.keywordsLimit) {
          this.isInclusionsInvalid = true
          this.inclusionsError = `max ${this.keywordsLimit} keywords accepted.`
        } else {
          const inclusionData = keywords.filter(entry => entry.trim() !== '')
          if (inclusionData.length === 0) {
            this.isInclusionEmpty = true
          }
          this.newNewsCategory.inclusionKeywords = inclusionData
        }
      } else {
        this.newNewsCategory.inclusionKeywords = []
        this.isInclusionsInvalid = false
        this.isInclusionEmpty = true
        this.inclusionsError = ''
      }
    },

    /**
     * Handles the keyword input in exclusions field and prepares the array from the string input using the delimiter as "\n"(newline).
     * @param {String} data
     * @public
     */
    handleExclusionInput (data) {
      if (data) {
        const keywords = data.split('\n')
        if (keywords.length > this.keywordsLimit) {
          this.isExclusionInvalid = true
          this.inclusionsError = `max ${this.keywordsLimit} keywords accepted.`
        } else {
          const exclusionData = keywords.filter(entry => entry.trim() !== '')
          this.newNewsCategory.exclusionKeywords = exclusionData
        }
      } else {
        this.newNewsCategory.exclusionKeywords = []
        this.isInclusionsInvalid = false
        this.inclusionsError = ''
      }
    },

    /**
     * Handles the form submission by validating the input and calls the UpdateNewsCategory API.
     * @public
     */
    async handleSubmit (e) {
      this.submitted = true
      if (this.categoryStatus === true) {
        this.newNewsCategory.status = 'active'
      } else {
        this.newNewsCategory.status = 'inactive'
      }
      if (!this.checkChanges() && this.newNewsCategory.moduleIds && this.newNewsCategory.moduleIds.length > 0) {
        if (!(this.isCategoryNameValid || this.isInclusionsInvalid || this.isExclusionsInvalid)) {
          const res = await this.updateNewsCategory({ updateNewsCategory: this.newNewsCategory, categoryId: this.$route.params.id })
          if (res === 200) {
            this.getDataById()
            this.getNewsCategoryListGlobalFilter({
              moduleId: this.userDetails.modules.find(
                (module) => module.module_name === 'esg-controversies'
              ).module_id,
              limit: 200,
              offset: 0
            })
          }
        } else {
          const message = (this.isInclusionsInvalid === true || this.isExclusionsInvalid === true || this.isCategoryNameValid)
          if (message) {
            Snackbar({ message: 'Invalid input', type: 'is-danger' })
          }
        }
      } else {
        const message = (this.newNewsCategory.moduleIds === null || this.newNewsCategory.moduleIds.length === 0) ? 'No module info found!' : 'No Updates Found'
        Snackbar({ message: message, type: 'is-danger' })
      }
    },

    /**
     * Gets called from handelSubmit function to check if there are any updates to put.
     */

    checkChanges () {
      const newInclusions = this.newNewsCategory.inclusionKeywords ? this.newNewsCategory.inclusionKeywords.join('\n') : ''
      const newExclusions = this.newNewsCategory.exclusionKeywords ? this.newNewsCategory.exclusionKeywords.join('\n') : ''
      if (this.formInclusions !== newInclusions) {
        return false
      }
      if (this.formExclusions !== newExclusions) {
        return false
      }

      if (this.newsCategoryDetails.modules !== null && this.newsCategoryDetails.modules[0].id !== this.newNewsCategory.moduleIds[0]) {
        return false
      } else if (this.newsCategoryDetails.modules === null && this.newNewsCategory.moduleIds && this.newNewsCategory.moduleIds.length > 0) {
        return false
      }

      if (this.newsCategoryDetails.parent !== null && this.newNewsCategory.parentId !== this.newsCategoryDetails.parent.id) {
        return false
      } else if (this.newsCategoryDetails.parent === null && this.newNewsCategory.parentId !== null) {
        return false
      }
      if (this.newNewsCategory.label !== this.newsCategoryDetails.label) {
        return false
      }
      if (this.newNewsCategory.status !== this.newsCategoryDetails.status) {
        return false
      }
      return true
    },

    /**
     * Gets called on click of the cancel button. It navigates back to the new category (list) page
     * @public
     */
    handleCancel () {
      this.$router.push('/admin/news-categories')
    }

  }
}
</script>

<style lang="scss" scoped>
  .new-clients-container {
    margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
    .new-client {
      .columns {
        margin: 0px;
        .required-error {
          position: absolute;
          color: red;
          position: absolute;
          font-size: 12px;
        }
        .column {
          .upload {
            width: 200px;
            height: 60px;
            align-items: center;
            justify-content: center;
            border: 1px solid #dbdbdb;
            border-radius: 4px;
            img {
              width: 200px;
              height: 58px;
              object-fit: contain;
              padding: 5px;
            }
          }
          .submit-button {
            display: flex;
            justify-content: flex-start;
            padding-top: 20px;
            .button {
              height: 100%;
              padding: 5px 10px;
              background: #2780eb;
              color: white;
              border: none;
              margin-right: 10px;
            }
          }
        }
        .column-flex {
          display: flex;
          align-items: center;
        }
      }
    }
  }

::v-deep .vue-treeselect {
          .vue-treeselect__placeholder {
              font-family: roboto;
              font-size: 14px;
          }
          .vue-treeselect__control {
            background: transparent;
            border: none;
            border: var(--tertiary-border-color) !important;
            border-radius: 4px !important;
            color: var(--user-dropdown-text-color) !important;
              input {
                color: var(--user-dropdown-text-color) !important;
              }
              .vue-treeselect__value-container {
                .vue-treeselect__single-value {
                  color: var(--user-dropdown-text-color) !important;
                  font-family: roboto;
                  font-size: 14px;
                }
              }
          }
            .vue-treeselect__menu {
              background: var(--dropdown-backgroud) !important;
              color: var(--secondary-text-color) !important;
              border: none;
              border: 1px solid lightgray;

              .vue-treeselect__option--highlight{
                background-color: var(--dropdown-background-category-hover) !important;
              }
            }
        }
        ::v-deep.vue-treeselect--single .vue-treeselect__option--selected {
          background: var(--dropdown-background-category-hover)
        }

</style>
